@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

@font-face {
  font-display: swap;
  font-family: 'Inter Regular';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/inter-v12-latin-regular.eot');
  src:
    url('/assets/fonts/inter-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/inter-v12-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/inter-v12-latin-regular.woff') format('woff'),
    url('/assets/fonts/inter-v12-latin-regular.ttf') format('truetype'),
    url('/assets/fonts/inter-v12-latin-regular.svg#Inter') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Inter Medium';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/inter-v12-latin-500.eot');
  src:
    url('/assets/fonts/inter-v12-latin-500.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/inter-v12-latin-500.woff2') format('woff2'),
    url('/assets/fonts/inter-v12-latin-500.woff') format('woff'),
    url('/assets/fonts/inter-v12-latin-500.ttf') format('truetype'),
    url('/assets/fonts/inter-v12-latin-500.svg#Inter') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Inter SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/inter-v12-latin-600.eot');
  src:
    url('/assets/fonts/inter-v12-latin-600.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/inter-v12-latin-600.woff2') format('woff2'),
    url('/assets/fonts/inter-v12-latin-600.woff') format('woff'),
    url('/assets/fonts/inter-v12-latin-600.ttf') format('truetype'),
    url('/assets/fonts/inter-v12-latin-600.svg#Inter') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Inter Bold';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/inter-v12-latin-700.eot');
  src:
    url('/assets/fonts/inter-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/inter-v12-latin-700.woff2') format('woff2'),
    url('/assets/fonts/inter-v12-latin-700.woff') format('woff'),
    url('/assets/fonts/inter-v12-latin-700.ttf') format('truetype'),
    url('/assets/fonts/inter-v12-latin-700.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Henkel GT Flexa Regular';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Henkel-GT-Flexa-Regular.otf');
}

@font-face {
  font-family: 'Henkel GT Flexa Medium';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/Henkel-GT-Flexa-Medium.otf');
}

@font-face {
  font-family: 'Henkel GT Flexa Bold';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/Henkel-GT-Flexa-Bold.otf');
}

.icon-send,
.icon-security,
.icon-privacy,
.icon-data-safety,
.icon-dot,
.icon-heart,
.icon-toggle-tip,
.icon-logo,
.icon-close-tip,
.icon-free-play,
.icon-email,
.icon-compare-mini,
.icon-document,
.icon-assistant-avatar,
.icon-csv-normal,
.icon-copy-normal,
.icon-close-bold,
.icon-crafted,
.icon-info,
.icon-guideline {
  background-image: url('/assets/images/icon-send.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  @apply w-[34px] h-[34px] inline-block;
}
.icon-security {
  background-image: url('/assets/images/icon-security.svg');
  @apply w-4 h-4;
}
.icon-privacy {
  background-image: url('/assets/images/icon-privacy.svg');
  @apply w-4 h-4;
}
.icon-data-safety {
  background-image: url('/assets/images/icon-data-safety.svg');
  @apply w-4 h-4;
}
.icon-dot {
  background-image: url('/assets/images/icon-dot.svg');
  @apply w-1 h-1;
}
.icon-heart {
  background-image: url('/assets/images/icon-heart.svg');
  @apply w-6 h-6;
}
.icon-toggle-tip {
  background-image: url('/assets/images/icon-toggle-tip.svg');
  @apply w-5 h-4;
}
.icon-logo {
  background-image: url('/assets/images/icon-logo.svg');
  @apply w-6 h-6;
}
.icon-close-tip {
  background-image: url('/assets/images/icon-close-tip.svg');
  @apply w-[13px] h-[13px];
}
.icon-free-play {
  background-image: url('/assets/images/icon-free-play.svg');
  @apply w-[50px] h-[38px];
}
.icon-email {
  background-image: url('/assets/images/icon-email.svg');
  @apply w-6 h-6 mr-[3px];
}
.icon-compare-mini {
  background-image: url('/assets/images/icon-compare-mini.svg');
  @apply w-6 h-6;
}
.icon-document {
  background-image: url('/assets/images/icon-document.svg');
  @apply w-6 h-6;
}
.icon-assistant-avatar {
  background-image: url('/assets/images/icon-assistant-avatar.svg');
  @apply w-8 h-8;
}
.icon-csv-normal {
  background-image: url('/assets/images/icon-csv-normal.svg');
  @apply w-6 h-6;
}
.icon-copy-normal {
  background-image: url('/assets/images/icon-copy-normal.svg');
  @apply w-6 h-6;
}
.icon-free-play-new-dialog {
  background-image: url('/assets/images/icon-free-play-new-dialog.svg');
  @apply w-6 h-6;
}
.icon-close-bold {
  background-image: url('/assets/images/icon-close-bold.svg');
  @apply w-4 h-4;
}
.icon-crafted {
  background-image: url('/assets/images/icon-crafted.svg');
  @apply w-6 h-6;
}
.icon-info {
  background-image: url('/assets/images/icon-info.svg');
  @apply w-3 h-3;
}
.icon-guideline {
  background-image: url('/assets/images/icon-guideline.svg');
  @apply w-[13px] h-[15px];
}

.new-dialog-menu {
  box-shadow: 0px 4px 59px 0px rgba(0, 0, 0, 0.1) !important;
  &.mat-mdc-menu-panel {
    @apply rounded-xl bg-white mt-2;
  }
  .mat-mdc-menu-content {
    @apply py-4;
    .mat-mdc-menu-item {
      &:hover {
        @apply bg-[#F2F6FB];
      }
      @apply w-[229px];
      .mat-mdc-menu-item-text {
        font-family: 'Inter Regular';
        @apply flex items-center text-[#021D33] text-[14px];
        .menu-icon {
          @apply mr-2;
        }
      }
    }
  }
}

.custom-table-container {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
  @apply w-full overflow-hidden rounded-lg mb-4;
  .custom-table {
    @apply w-full border-collapse;
    thead {
      font-family: 'Inter Medium';
      @apply bg-[#42424B] text-[#FCFCFD];
      tr {
        th:first-child {
          @apply rounded-tl-lg overflow-hidden;
        }
        th:last-child {
          @apply rounded-tr-lg overflow-hidden;
        }
        th {
          @apply border-t-0;
          white-space: normal;
          word-break: break-word;
        }
      }
    }
    tbody {
      font-family: 'Inter Regular';
      @apply bg-white text-[#021D33];
      tr {
        td:first-child {
          @apply bg-[#FCFCFD];
        }
      }
    }
    tr {
      @apply h-[43px];
      th,
      td {
        @apply px-4 py-3 text-[16px] leading-[19px]  text-center border-solid border-[1px] border-[#E4E8EE];
      }
      th:first-child,
      td:first-child {
        @apply text-left border-l-0;
      }
      th:last-child,
      td:last-child {
        @apply border-r-0;
      }
    }
  }

  .table-action {
    @apply bg-[#FCFCFD] flex items-center justify-end h-[61px] px-6;
    .table-action-item {
      font-family: 'Inter Regular';
      color: rgba(2, 29, 51, 0.7);
      @apply flex items-center px-1 cursor-pointer text-[16px] h-7 rounded-md transition-all;
      & + .table-action-item {
        @apply ml-6;
      }
      .action-name {
        @apply ml-[6px];
      }
      &:hover {
        @apply bg-[#DBE3EE] text-[#356FFF] transition-all;
        .icon-copy-normal {
          background-image: url('/assets/images/icon-copy-active.svg');
        }
        .icon-csv-normal {
          background-image: url('/assets/images/icon-csv-active.svg');
        }
      }
    }
  }
}

.comparison-dialog,
.summary-dialog {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      @apply rounded-xl border-[1px] border-solid border-[#E4E8EE];
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
      overflow: hidden;
    }
    .mdc-dialog__title {
      @apply text-[#021D33];
    }
    .mdc-dialog__content {
      @apply text-[#9BA4AE];
    }
  }
}

.summary-dialog {
  .henkel-gpt-file-upload .file-item {
    margin-top: 62px !important;
  }
}

.disclaimer-panel {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    @apply rounded-2xl shadow-none w-[700px];
    .mat-mdc-dialog-title {
      font-family: 'Inter Bold';
      @apply flex items-center justify-center mt-[54px] mb-9 px-[63px] py-0 text-[32px] h-[39px] text-[#1D1A1A];
      .disclaimer-playground {
        @apply text-[rgb(243,38,38)];
      }
      .disclaimer-ai {
        font-family: 'Inter Regular';
        @apply text-[#F32626];
      }
    }
    .mat-mdc-dialog-content {
      @apply px-8 pr-[51px] pb-0;
      .disclaimer-item {
        @apply flex;
        .disclaimer-icon {
          @apply w-[41px] h-[39px] flex-none mr-4;
        }
        .disclaimer-content {
          .title {
            font-family: 'Inter Bold';
            @apply text-[#1D1A1A] text-[16px] leading-[19px] mb-2;
          }
          .description {
            font-family: 'Inter Regular';
            @apply text-[#1D1A1A] text-[16px] leading-[19px];
          }
          .highlight {
            font-family: 'Inter Bold';
          }
          .security-guidelines {
            @apply underline outline-none;
            &:hover {
              @apply text-[#9BA4AE];
            }
          }
        }
        & + .disclaimer-item {
          @apply mt-6;
        }
      }
    }
    .mat-mdc-dialog-actions {
      @apply flex pl-[89px] pr-[81px] pt-[63px] pb-[34px] items-center;
      .tips {
        font-family: 'Inter Regular';
        color: rgba(0, 0, 0, 0.5);
        @apply flex-1 mr-[35px] text-[14px] leading-[17px];
      }
      .btn-dialog {
        font-family: 'Inter Medium';
        @apply bg-[#356FFF] cursor-pointer outline-none text-white px-[18px] h-[43px] rounded-md text-[16px] transition-all duration-200 ease-linear;
        &:hover,
        &.active {
          @apply bg-[#91ACF1] transition-all duration-200 ease-linear;
        }
      }
    }
  }
}
.disclaimer-backdrop {
  @apply bg-[#00000080];
}
